import {Component, OnInit} from '@angular/core';
import {Product} from "../../../../models/product";
import {ActivatedRoute, Router} from "@angular/router";
import {ShopService} from "../../service/shop.service";
import {of, switchMap} from "rxjs";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {goToUrl} from "../../../../services/util/util";
import {Modal, Toast} from "tw-elements";
import {DropdownOption} from "../../../../components/dropdown/model/dropdown-option";
import {environment} from "../../../../environments/environment";
import {AuthService} from "@auth0/auth0-angular";
import {Discount} from "../../../../models/discount";

@Component({
    selector: 'app-product-manage',
    templateUrl: './product-manage.component.html',
    styleUrl: './product-manage.component.css'
})
export class ProductManageComponent implements OnInit {

    product!: Product;

    discounts: Discount[] = [];

    isEditMode: boolean = false;

    toastMessage: string = '';

    productControl: UntypedFormGroup = new UntypedFormGroup({
        name: new UntypedFormControl(''),
        category: new UntypedFormControl(''),
        designID: new UntypedFormControl(''),
        description: new UntypedFormControl(''),
        slug: new UntypedFormControl(''),
        keywords: new UntypedFormControl(''),
        subCategory: new UntypedFormControl(''),
        images: new UntypedFormControl(''),
        availableSizes: new UntypedFormControl(''),
        availableColors: new UntypedFormControl(''),
        originalPrice: new UntypedFormControl(''),
        price: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
        created: new UntypedFormControl(''),
        discount: new UntypedFormControl('')
    });


    categoryOptions: DropdownOption[] = [
        {
            label: 'Маица',
            value: 'T_SHIRT'
        },
        {
            label: 'Блузон',
            value: 'SWEAT_SHIRT'
        },
        {
            label: 'Дуксер',
            value: 'HOODIE'
        },
        {
            label: 'Дрес',
            value: 'JERSEY'
        },
        {
            label: 'Знаме',
            value: 'FLAG'
        },
        {
            label: 'Шише',
            value: 'BOTTLE'
        },
        {
            label: 'Останато',
            value: 'OTHER'
        }
    ]

    discountOptions: DropdownOption[] = [];

    constructor(public auth: AuthService,
                private route: ActivatedRoute,
                private shopService: ShopService,
                protected readonly router: Router) {
    }

    ngOnInit(): void {
        this.shopService.getAllDiscounts().subscribe(
            discounts => {
                this.discounts = discounts;
                this.discountOptions = this.discounts.map(discount => ({
                    label: discount.name,
                    value: discount.id!.toString()
                }));
            });

        this.route.paramMap.pipe(
            switchMap(params => {
                const id = params.get('id');
                if (id) {
                    this.isEditMode = true;
                    return this.shopService.getProductById(+id);
                } else {
                    this.isEditMode = false;
                    return of(null);
                }
            })
        ).subscribe(product => {
            if (this.isEditMode && product) {
                this.product = product;
                this.setProductValues(product);
            }
        });
    }

    get nameControl(): UntypedFormControl {
        return this.productControl.get('name') as UntypedFormControl;
    }

    get categoryControl(): UntypedFormControl {
        return this.productControl.get('category') as UntypedFormControl;
    }

    get designIDControl(): UntypedFormControl {
        return this.productControl.get('designID') as UntypedFormControl;
    }

    get descriptionControl(): UntypedFormControl {
        return this.productControl.get('description') as UntypedFormControl;
    }

    get slugControl(): UntypedFormControl {
        return this.productControl.get('slug') as UntypedFormControl;
    }

    get keywordsControl(): UntypedFormControl {
        return this.productControl.get('keywords') as UntypedFormControl;
    }

    get subCategoryControl(): UntypedFormControl {
        return this.productControl.get('subCategory') as UntypedFormControl;
    }

    get imagesControl(): UntypedFormControl {
        return this.productControl.get('images') as UntypedFormControl;
    }

    get availableSizesControl(): UntypedFormControl {
        return this.productControl.get('availableSizes') as UntypedFormControl;
    }

    get availableColorsControl(): UntypedFormControl {
        return this.productControl.get('availableColors') as UntypedFormControl;
    }

    get originalPriceControl(): UntypedFormControl {
        return this.productControl.get('originalPrice') as UntypedFormControl;
    }

    get priceControl(): UntypedFormControl {
        return this.productControl.get('price') as UntypedFormControl;
    }

    get createdControl(): UntypedFormControl {
        return this.productControl.get('created') as UntypedFormControl;
    }

    get discountControl(): UntypedFormControl {
        return this.productControl.get('discount') as UntypedFormControl;
    }

    setProductValues(product: Product) {
        this.productControl.patchValue({
            name: product.name,
            category: product.category,
            designID: product.designID,
            description: product.description,
            slug: product.slug,
            keywords: product.keywords,
            subCategory: product.subCategory,
            images: product.images,
            availableSizes: product.availableSizes,
            availableColors: product.availableColors,
            originalPrice: product.originalPrice,
            price: product.price,
            created: product.created,
            discount: product.discount?.name
        });
    }

    addProduct() {
        const newProduct: Partial<Product> = {};
        const toArray = (value: string | string[] | undefined): string[] => {
            if (typeof value === 'string') {
                return value.split(',').map(item => item.trim());
            }
            return Array.isArray(value) ? value : [];
        };

        if (this.nameControl.value) newProduct.name = this.nameControl.value;
        if (this.categoryControl.value) newProduct.category = this.categoryControl.value;
        if (this.designIDControl.value) newProduct.designID = this.designIDControl.value;
        if (this.descriptionControl.value) newProduct.description = this.descriptionControl.value;
        if (this.slugControl.value) newProduct.slug = this.slugControl.value;
        if (this.keywordsControl.value) newProduct.keywords = this.formatKeywords(this.keywordsControl.value);
        if (this.subCategoryControl.value) newProduct.subCategory = this.subCategoryControl.value;
        if (this.availableSizesControl.value) newProduct.availableSizes = toArray(this.availableSizesControl.value);
        if (this.availableColorsControl.value) newProduct.availableColors = toArray(this.availableColorsControl.value);
        if (this.imagesControl.value) newProduct.images = toArray(this.imagesControl.value);
        if (this.originalPriceControl.value) newProduct.originalPrice = this.originalPriceControl.value;
        if (this.priceControl.value) newProduct.price = this.priceControl.value;
        if (this.createdControl.value) newProduct.created = this.createdControl.value;
        if (this.discountControl.value) newProduct.discount = this.discountControl.value;


        this.shopService.addProduct(newProduct).subscribe({
            next: () => {
                alert("Продуктот е успешно додаден");
                this.productControl.reset();
                goToUrl(this.router, "/shop");
            },
            error: () => alert("Грешка при додавање на продукт"),
        });
    }

    updateProduct() {
        const images = typeof this.imagesControl.value === 'string'
            ? this.imagesControl.value.split(',').map(img => img.trim())
            : Array.isArray(this.imagesControl.value)
                ? this.imagesControl.value
                : [];

        const product: Partial<Product> = {
            id: this.product?.id,
            name: this.nameControl.value || this.product?.name,
            category: this.categoryControl.value || this.product?.category,
            designID: this.designIDControl.value || this.product?.designID,
            description: this.descriptionControl.value || this.product?.description,
            slug: this.slugControl.value || this.product?.slug,
            keywords: this.formatKeywords(this.keywordsControl.value) || this.product?.keywords,
            subCategory: this.subCategoryControl.value || this.product?.subCategory,
            images: images,
            availableSizes: this.availableSizesControl.value || this.product?.availableSizes,
            availableColors: this.availableColorsControl.value || this.product?.availableColors,
            originalPrice: this.originalPriceControl.value || this.product?.originalPrice,
            price: this.priceControl.value || this.product?.price,
            created: this.createdControl.value || this.product?.created,
        };

        this.shopService.updateProduct(product).subscribe({
            next: () => alert("Успешно ажурирање на податоци"),
            error: () => alert("Грешка при ажурирање на податоци"),
        });
    }

    deleteProduct() {
        this.shopService.deleteProduct(this.product.id!).subscribe({
            next: () => {
                alert("Успешно избришан продукт");
                goToUrl(this.router, "/shop");
            },
            error: () => alert("Грешка при бришење на продукт"),
        });
    }

    openToast(message: string) {
        const toast = new Toast(document.getElementById("toast"));
        this.toastMessage = message;
        toast.show();
    }

    openConfirmationModal(): void {
        if (this.productControl.status === 'VALID') {
            const confirmationModal = new Modal(document.getElementById("confirmationModal"));
            confirmationModal.show();
        } else {
            this.openToast('Пополнете ја формата');
        }
    }

    private formatKeywords(value: string | undefined): string[] | undefined {
        if (typeof value === 'string') {
            return value.split(',')
                .map(keyword => keyword.trim())
                .filter(keyword => keyword.length > 0);
        }
        return undefined;
    }

    onCategoryChange(selectedValue: string): void {
        this.categoryControl.setValue(selectedValue);
    }

    onDiscountChange(selectedValue: string): void {
        this.discountControl.setValue(selectedValue);
    }

    applyDiscount(): void {
        if (this.product?.id && this.discountControl.value) {
            this.shopService.applyDiscount(this.product.id, this.discountControl.value)
                .subscribe({
                    next: (updatedProduct) => {
                        this.setProductValues(updatedProduct);
                        alert("Попустот е успешно аплициран!");
                    },
                    error: (error) => console.error('Error applying discount:', error)
                });
        }
    }

    removeProductDiscount(): void {
        if (this.product?.id) {
            this.shopService.removeProductDiscount(this.product.id)
                .subscribe({
                    next: (updatedProduct) => {
                        this.setProductValues(updatedProduct);
                        alert("Попустот е отстранет!");
                    },
                    error: (error) => console.error('Error removing discount:', error)
                });
        }
    }

    protected readonly document = document;

    protected readonly environment = environment;
}